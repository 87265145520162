import loadable from '@loadable/component';
import { WithLazyHydration } from '../WithLazyHydration';

const VideoPlaylistContentLoadable = loadable(
  () => import('./VideoPlaylistContent'),
  {
    resolveComponent: components => components.VideoPlaylistContent
  }
);

export const VideoPlaylistContent = WithLazyHydration(
  VideoPlaylistContentLoadable,
  {
    name: 'VideoPlaylistContent'
  }
);
