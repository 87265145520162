import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { VideoPlaylistContent } from '../../VideoPlaylistContent';
import { theme } from '../../../domain/theme';
import { ParagraphWrapper } from '../ParagraphWrapper';

const VideoPlaylistInner = ({ data, bundle, isAboveTheFold }) => {
  let { widthSize } = data;
  if (!widthSize) {
    widthSize = 'medium';
  }
  const maxWidths = {
    medium: bundle === 'article' ? '100%' : '1080px',
    large: '1600px',
    full: '100%'
  };
  const styles = {
    container: css`
      background-color: ${data.backgroundColor};
      padding-top: ${theme.spacing('xs')};
      clear: both;
      width: 100%;
      max-width: ${maxWidths[widthSize]};
      margin: 0 auto;
    `,
    title: css`
      font-size: ${theme.font.size('xl')};
      ${theme.font.family('boldWeb')};
      text-transform: uppercase;
      color: ${data.backgroundColor === 'white' ? `#000` : `#fff`};
      text-align: ${data.textAlign || 'start'};
      padding: ${theme.spacing.parse('0 $base')};
      ${theme.mq.small_desktop} {
        padding: 0 2.8rem;
      }
      margin: 0 auto;
      max-width: 1600px;
    `
  };

  return data.articleVideoPlaylist?.entity?.videoPlaylist?.entity?.videoRef ? (
    <section css={styles.container}>
      {data.hideTitle ? null : (
        <h2 css={styles.title}>
          {data.videoPlaylistTitle ||
            data.articleVideoPlaylist.entity.promoTitle ||
            data.articleVideoPlaylist.entity.title}
        </h2>
      )}
      <VideoPlaylistContent
        videos={data.articleVideoPlaylist.entity.videoPlaylist.entity.videoRef}
        backgroundColor={data.backgroundColor}
        hideVideoTitleAndDescriptions={data.hideVideoTitleAndDescriptions}
        carouselVerticalPositioning={data.carouselVerticalPositioning}
        bundle={bundle}
        isAboveTheFold={isAboveTheFold}
      />
    </section>
  ) : (
    <div />
  );
};

VideoPlaylistInner.defaultProps = {
  bundle: null,
  isAboveTheFold: false.valueOf
};

VideoPlaylistInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  bundle: PropTypes.string,
  isAboveTheFold: PropTypes.bool
};

export const VideoPlaylist = ParagraphWrapper(VideoPlaylistInner);
